import { Box, Stack, Tooltip, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';

type Props = {
  title: string | ReactNode;
  complement?: string;
  complementTooltip?: string;
  id?: string;
  actionBtn?: ReactNode;
};

export const SectionTitle: FC<Props> = (props: Props) => {
  const {
    title,
    id = 'coucou',
    complement = null,
    complementTooltip = null,
    actionBtn = undefined,
  } = props;

  const titleIsString = typeof title === 'string';

  return (
    <Box
      id={id}
      component="header"
      p={{ none: '0', md: '0 1.5rem', lg: '0 4rem' }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack direction="row" spacing={2}>
          {titleIsString ? (
            <Typography variant="h2">{title}</Typography>
          ) : (
            <>{title}</>
          )}
          {complement && (
            <Tooltip title={complementTooltip} placement="right">
              <Typography variant="h2" color="primary.main">
                {complement}
              </Typography>
            </Tooltip>
          )}
        </Stack>
        {actionBtn && actionBtn}
      </Stack>
    </Box>
  );
};
