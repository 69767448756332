'use client';

import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material';
import { IconButton, Popover } from '@mui/material';
import {
  type ComponentProps,
  type FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { CommentForm } from './comment-form';

type Props = ComponentProps<typeof CommentForm> & {
  locationId: string;
  disabled?: boolean;
};

export const Comment: FC<Props> = (props) => {
  const {
    locationId,
    onUpsert: onUpsertAction,
    onDelete: onDeleteAction,
    content = undefined,
    disabled = false,
    ...rest
  } = props;

  const [hasComment, setHasComment] = useState<boolean>(!!content);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const id = useMemo(
    () => (open ? `${locationId}-popover` : undefined),
    [open, locationId]
  );

  const onUpsert = useCallback(
    async (data: { content: string }) => {
      const res = await onUpsertAction(data);
      setHasComment(true);
      return res;
    },
    [onUpsertAction]
  );

  const onDelete = useCallback(async () => {
    const res = await onDeleteAction();
    setHasComment(false);
    return res;
  }, [onDeleteAction]);

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ position: 'relative', zIndex: 1 }}
        disabled={disabled}
      >
        {hasComment ? (
          <CommentOutlined fontSize="small" color="primary" />
        ) : (
          <AddCommentOutlined fontSize="small" />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CommentForm
          {...rest}
          content={content}
          onUpsert={onUpsert}
          onDelete={onDelete}
          locationId={locationId}
        />
      </Popover>
    </>
  );
};
