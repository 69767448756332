'use client';

import { Trans } from '@lingui/macro';
import { DeleteForever } from '@mui/icons-material';
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import { type FC, useCallback, useMemo, useState } from 'react';

type Props = {
  locationId: string;
  onConfirm: () => void;
  disabled?: boolean;
};

export const DeleteButton: FC<Props> = (props) => {
  const { onConfirm: onConfirmAction, disabled = false, locationId } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const id = useMemo(
    () => (open ? `${locationId}-popover` : undefined),
    [open, locationId]
  );

  const onConfirm = useCallback(() => {
    onConfirmAction();
    handleClose();
  }, [onConfirmAction, handleClose]);

  return (
    <>
      <IconButton
        color="error"
        size="small"
        onClick={handleClick}
        disabled={disabled}
      >
        <DeleteForever fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack gap={1} padding={1}>
          <Typography fontSize="small">
            <Trans>Êtes-vous sûr de vouloir supprimer ce commentaire ?</Trans>
          </Typography>
          <Stack
            gap={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              type="button"
              variant="outlined"
              color="gray"
              onClick={handleClose}
              size="small"
            >
              <Trans>Annuler</Trans>
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="error"
              onClick={onConfirm}
              size="small"
            >
              <Trans>Confirmer</Trans>
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
